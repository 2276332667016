








































































import { Component, Vue } from "vue-property-decorator";
import MqttClientM from "@/mqtt/mqtt";
import { globalData } from "@/utils/config";
import ExportExlPopwindow from "@/components/ExportExlPopwindow.vue";
import { CommonListRes, Page, CommonRes } from "@/api/common";
import {isNewVersion} from "@/utils/appUtil";
import {
  getFaultList,
  Fault,
  deleteFault,
  GetFaultListReq
} from "@/api/fault";
@Component({
  components: {
    ExportExlPopwindow,
  },
})
export default class Index extends Vue {
  private active: number = 0;
  public show: boolean = false;
  public linkText: string = "";
  private faultList: Fault[] = [];
  private badge: string="";
  private isNew: boolean = false;
  created() {
    console.log("home created");
    // this.autoChange();
    // this.$i18n.locale = "zh_CN";
  }
  activated() {
    console.log("home activated");
    this.isNewVersion()
    MqttClientM.getInstance().init(
      this.$t("loginOther").toString(),
      this.$t("confirm").toString(),
      this
    );
  }
  deactivated () {
    console.log('home deactivated');
  }
   mounted () {
    console.log('home robot mounted');
  }

   private closeDialog() {
    this.show = false;
  }
  private async nowLoginEvent() {
    this.show = false;
  }

  private async goToWorkDetail() {
    this.$router.push({
      path: "/workDetail",
      query: { id: "1" },
    });
  }

  private async goToSetting() {
    this.$router.push({
      path: "/setting",
    });
  }
  
  private isNewVersion() {
    this.isNew = isNewVersion();
    console.log("isNewVersion :" + this.isNew)  
  }
  private async getUndetailNum () {
  let req: GetFaultListReq = {
    page: 1,
    limit: 1000
  }
    let res: CommonListRes<Page<Fault>> = await getFaultList(req);
    if (res.code == 0) {
      this.faultList = this.faultList.concat(res.page.list);
      let undeatilNum: number = 0;
      this.faultList.forEach(element => {
        if (element.state == 0) {
          undeatilNum++
        }
      });
      if (undeatilNum == 0) {
        this.badge = "";
      } else {
        this.badge = undeatilNum + "";
      }
    }
  }
  private onChange(index: number) {
    console.log("onChange", index)
    this.badge = "";
    //x this.getUndetailNum();
  }
}
