




















import { Toast } from "vant";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class RegisterDialog extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  private show!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  private linkText: string = '';
  private close() {
    this.$emit("close");
  }

  private onCopy() {
    Toast(this.$t("copysuccess"))
  }
  private onCopyerror() {
    Toast(this.$t("copyfailed"))
  }
  private share() {
     this.$emit("share");
  }
  private toLink(){
    window.location.href= this.linkText;
  }
}
