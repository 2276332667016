var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e(),_c('van-tabbar',{attrs:{"route":"","fixed":"","active-color":"#4E8AE9","inactive-color":"#000000"},on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"replace":"","to":"/robot"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('@/assets/img/robotSelect.png')
              : require('@/assets/img/robotUnselect.png')}})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("robot")))])]),_c('van-tabbar-item',{attrs:{"replace":"","to":"/work"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('@/assets/img/workSelect.png')
              : require('@/assets/img/workUnselect.png')}})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("workRecord")))])]),_c('van-tabbar-item',{attrs:{"replace":"","to":"/fault","badge":_vm.badge},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('@/assets/img/after_sales_select.png')
              : require('@/assets/img/after_sales_unSelect.png')}})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("after_sale_service")))])]),_c('van-tabbar-item',{attrs:{"replace":"","to":"/setting","dot":_vm.isNew},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active
              ? require('@/assets/img/settingSelect.png')
              : require('@/assets/img/settingUnselect.png')}})]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("setting")))])])],1),_c('ExportExlPopwindow',{attrs:{"show":_vm.show,"linkText":_vm.linkText},on:{"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }